// themeSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userReservations: [],
  userQueueInfo: {},
  dineinBranchesInfo: {},
  existingReservationInfo: null,
  isEditReservation: false,
};

const dineinSlice = createSlice({
  name: "dinein",
  initialState,
  reducers: {
    setDineinQueueCustomerInfo: (state, action) => {
      // Simply replace the entire userQueueInfo with the new data
      state.userQueueInfo = action.payload;
    },

    filterPrevQueueData: (state, action) => {
      const { branch_id } = action.payload;

      // Clear userQueueInfo if its branch_id matches the payload
      if (state.userQueueInfo.branch_id === branch_id) {
        state.userQueueInfo = {};
      }
    },

    filterPrevReserveData: (state, action) => {
      if (state) {
        state.userReservations = state.userReservations.filter(
          (item) => item.branch_id !== action.payload
        );
      }
    },
    setDineinReservationInfo: (state, action) => {
      if (state) {
        state.userReservations.push(action.payload);
      }
    },
    setUserReservations: (state, action) => {
      if (state) {
        state.userReservations = action.payload;
      }
    },
    updateDineinQueueCustomerInfo: (state, action) => {
      const { queue_id } = action.payload;

      // Check if the queue_id matches, then update the userQueueInfo
      if (state.userQueueInfo.queue_id == queue_id) {
        state.userQueueInfo = {
          ...state.userQueueInfo,
          ...action.payload, // Spread the updated fields
        };
      }
    },
    updateReservationInfo: (state, action) => {
      const index = state.userReservations.findIndex(
        (element) => element.reservation_id === +action.payload.reservation_id
      );

      if (index !== -1) {
        // If a matching element is found, replace it with action.payload
        state.userReservations[index] = action.payload;
      }
    },
    removeQueueRequest: (state, action) => {
      // Clear userQueueInfo if the queue_id matches
      if (state.userQueueInfo.queue_id == action.payload) {
        state.userQueueInfo = {};
      }
    },
    removeReservationRequest: (state, action) => {
      if (state) {
        state.userReservations = state.userReservations.filter(
          (element) => element.reservation_id !== action.payload
        );
      }
      if(action.payload == state.existingReservationInfo?.reservation_id){
        state.existingReservationInfo = null;
        state.isEditReservation = false;
      }
    },
    resetQueue: (state, action) => {
      state.userQueueInfo = {};
    },
    resetReservation: (state, action) => {
      state.userReservations = [];
    },
    setDineinBranchesInfo: (state, action) => {
      if (state) {
        state.dineinBranchesInfo = action.payload;
      }
    },
    setReservationInfoForEdit: (state, action) => {
      if (state) {
        state.existingReservationInfo = action.payload;
      }
    },
    removeReservationInfoForEdit: (state, action) => {
      if (state) {
        state.existingReservationInfo = null;
      }
    },
    setIsEditReservation: (state, action) => {
      if (state) {
        state.isEditReservation = action.payload;
      }
    },
  },
});

export const {
  setDineinQueueCustomerInfo,
  setDineinBranchesInfo,
  removeQueueRequest,
  updateDineinQueueCustomerInfo,
  resetQueue,
  setDineinReservationInfo,
  resetReservation,
  updateReservationInfo,
  removeReservationRequest,
  setUserReservations,
  setReservationInfoForEdit,
  setIsEditReservation,
  filterPrevQueueData,
  filterPrevReserveData,
  removeReservationInfoForEdit,
} = dineinSlice.actions;

export default dineinSlice.reducer;
