import React, { useEffect } from "react";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import { Trans, useTranslation } from "react-i18next";

import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Navbar from "../../components/header";
import Typography from "@mui/material/Typography";
import { MuiOtpInput } from "mui-one-time-password-input";
import { Footer } from "../../components/footer/footerContent";
import { paths } from "../../routes/paths";
import InfoModal from "../../components/modal/InfoModal";
import { useMutation } from "@tanstack/react-query";
import verifyOTP from "../../api/customer/verifyOTP";
import resendOTP from "../../api/customer/resendOTP";
import getUserInfo from "../../api/customer/getUserInfo";
import { useDispatch } from "react-redux";
import "./style.css";
import addToCartApiCall from "../../api/cart/addToCart";
import { addToCart, resetCart } from "../../store/reducers/cartSlice";
import { addToken, addUserInfo } from "../../store/reducers/authSlice";
import clearCart from "../../api/cart/clearCart";

import {
  removeTypeStatus,
  setPickupBranchID,
  setPickupBranchName,
  setTabStatus,
  setActiveButton,
} from "../../store/reducers/resturantsSlice";
import {
  // filterPrevQueueData,
  filterPrevReserveData,
  // setDineinQueueCustomerInfo,
  setDineinReservationInfo,
} from "../../store/reducers/dineinSlice";

const MODAL_INFO_TYPE = {
  INVALID_OTP: "invalid-otp",
  OTP_TIMEOUT: "otp-timeout",
};

function PhoneNumberVerificationOTP() {
  const { t, i18n } = useTranslation("translation");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();

  const [searchParams] = useSearchParams();

  const [otpTimeout, setOtpTimeout] = React.useState(60);
  const [infoType, setInfoType] = React.useState(MODAL_INFO_TYPE.INVALID_OTP);
  const [open, setOpen] = React.useState(false);
  const [otp, setOtp] = React.useState("");
  const [mobileCountryCode, setMobileCountryCode] = React.useState("");
  const [mobileNumber, setMobileNumber] = React.useState("");
  const mobile_country_code = "+" + searchParams?.get("mobile_country_code");
  const mobile_number = searchParams?.get("mobile");
  // const sessionTimeout = 3600;clg
  const isAfterNewLocation = JSON.parse(
    sessionStorage?.getItem("storeAddress")
  );

  const handleChange = (otp) => {
    setOtp(otp);
  };

  const clearCartItems = (token) => {
    dispatch(resetCart());
    dispatch(removeTypeStatus());
    clearCart(token)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const { mutate: verifyOtp } = useMutation({
    mutationFn: verifyOTP,
    onSuccess: (data) => {
      localStorage.setItem("token", data.data.token);
      dispatch(addToken(data.data.token));
    
      // setTimeout(() => {
      //   logout(data.data.token)
      //     .then(() => {
      //       dispatch(resetCart());
      //       dispatch(clearAddressList());

      //       localStorage.clear();
      //       sessionStorage.removeItem("cartEmpty");

      //       window.alert("Your session has expired. Please log in again.");
      //       navigate(paths.phone_verification_index, {
      //         state: { register: true },
      //       });
      //     })
      //     .catch((e) => {
      //       console.error(e);
      //     });
      // }, sessionTimeout * 1000);

      clearCartItems(data.data.token);

      getUserInfo(data.data.token).then((res) => {
        const { first_name, last_name } = res.data;
        const mobileCountryCodeWithoutPlus = mobileCountryCode.substring(1);

        if (state?.guest !== undefined) {
          const cartItem = state?.ItemDataObject;
          const token = data.data.token;

          // dispatch(addToCart(cartItem));
          state.filterSelectedNow !== "dinein" &&
            addToCartApiHandler(cartItem, token);

          if (state?.typeStatus) {
            dispatch(setTabStatus(state?.typeStatus));

            if (state?.pickupBranch) {
              const pickupBranchName =
                i18n.language === "ar"
                  ? state?.pickupBranch?.name_ar
                  : state?.pickupBranch?.name_en;

              dispatch(setPickupBranchID(state?.pickupBranch?.id));
              dispatch(setPickupBranchName(pickupBranchName));
            }
          }

          if (state.filterSelectedNow === "dinein") {
            // if (state?.dineinType === "queue") {
            //   dispatch(filterPrevQueueData(state?.info?.branch_id));
            //   dispatch(setDineinQueueCustomerInfo({ ...state?.info }));
            // } 
            // else 
            if (state?.dineinType === "reserve") {
              dispatch(filterPrevReserveData(state?.info?.branch_id));

              dispatch(setActiveButton("reservation"));

              dispatch(setDineinReservationInfo({ ...state?.info }));
            }
          }
        }

        if (state?.autoRedirect !== undefined) {
          const cartItemDataForApi = state?.cartItemDataForApi;
          const inCart = state?.addtoCart;
          const token = data.data.token;

          if (state?.typeStatus) {
            dispatch(setTabStatus(state?.typeStatus));

            if (state?.pickupBranch) {
              const pickupBranchName =
                i18n.language === "ar"
                  ? state?.pickupBranch?.name_ar
                  : state?.pickupBranch?.name_en;

              dispatch(setPickupBranchID(state?.pickupBranch?.id));
              dispatch(setPickupBranchName(pickupBranchName));
            }

            if (state?.typeStatus === "pickup") {
              if (isAfterNewLocation !== null) {
                const storeAddress = {
                  ...isAfterNewLocation,
                  addNewAddress: false,
                  restaurantName: isAfterNewLocation?.restaurantName,
                };

                sessionStorage.setItem(
                  "storeAddress",
                  JSON.stringify(storeAddress)
                );
              }
            }
          }

          // dispatch(addToCart(inCart));
          addToCartApiCall(cartItemDataForApi, token)
            .then((res) => {
              dispatch(addToCart({ ...inCart, cart_id: res?.data?.cart_id }));
            })
            .catch((err) => {
              console.log(err);
            });
        }

        dispatch(addUserInfo(res.data));

        first_name && last_name
          ? state?.guest !== undefined || state?.autoRedirect !== undefined
            ? navigate(
                `${paths.menu}/restaurant/${state?.restaurant_id}/${state?.typeStatus}`
              )
            : state?.filterSelectedNow !== undefined &&
              state?.filterSelectedNow === "dinein"
            ? navigate(
                `${paths.menu}/restaurant/${state?.restaurant_id}/${state?.filterSelectedNow}`,
                { state: state }
              )
            : navigate(paths.home)
          : navigate(
              paths.customer_registration +
                "?" +
                createSearchParams({
                  mobile: mobileNumber,
                  mobile_country_code: mobileCountryCodeWithoutPlus,
                }),
              { state: { state } }
            );
      });
    },
    onError: (error) => {
      setInfoType(MODAL_INFO_TYPE.INVALID_OTP);
      setOpen(true);
    },
  });

  const variantsForApi = (variantID, optionId, quantity) => {
    const menuItemDetails = state?.menuItemDetails;
    const { data } = menuItemDetails;

    const dataVariants = data.variants?.find((val) => val.id === variantID);

    if (!dataVariants) return [];
    const dataOptions = dataVariants.options?.map((val, index) => {
      return {
        option_id: val.id,
        option_quantity:
          val.id == (optionId[index] || optionId)
            ? quantity[index]?.quantity || 1
            : 0,
      };
    });
    return dataOptions;
  };

  const getSelectedItemsIds = (selectedItems) => {
    const idsArr = selectedItems.map((val) => val.id);

    return idsArr;
  };

  const addToCartApiHandler = (cartItem, token) => {
    const sideVariant = cartItem?.variants?.sideVariant[0]?.variantId;
    const sizeVariant = cartItem?.variants?.sizeVariant?.variantId;
    const toppingVariant = cartItem?.variants?.toppingVariant[0]?.variantId;
    const customVariant = cartItem?.variants?.customVariant[0]?.variantId;

    const cartItemDataForApi = {
      item_code: cartItem.item_code,
      item_id: cartItem.id,
      quantity: cartItem.quantity,
      topping_variant_id: toppingVariant || null,
      side_variant_id: sideVariant || null,
      size_variant_id: sizeVariant || null,
      custom_variant_id: customVariant || null,
      size_variant: variantsForApi(
        sizeVariant,
        cartItem.variants?.sizeVariant?.id
      ),
      topping_variant: variantsForApi(
        toppingVariant,
        getSelectedItemsIds(cartItem.variants?.toppingVariant),
        cartItem.variants?.toppingVariant[0]?.quantity
      ),
      custom_variant: variantsForApi(
        customVariant,
        getSelectedItemsIds(cartItem.variants?.customVariant),
        cartItem.variants?.customVariant
      ),
      side_variant: variantsForApi(
        sideVariant,
        getSelectedItemsIds(cartItem.variants?.sideVariant)
      ),
      branch_id: cartItem?.branch_id,
    };

    addToCartApiCall(cartItemDataForApi, token)
      .then((res) => {
        dispatch(addToCart({ ...cartItem, cart_id: res?.data?.cart_id }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const { mutate: resendOtp } = useMutation({
    mutationFn: resendOTP,
    onSuccess: (data) => {
      setOtpTimeout(60);
      setOpen(false);
      setOtp("");
    },
  });

  useEffect(() => {
    if (otpTimeout > 0) {
      let timer1 = setTimeout(() => setOtpTimeout(otpTimeout - 1), 1000);

      return () => {
        clearTimeout(timer1);
      };
    }
  }, [otpTimeout]);

  const validateOTP = () => {
    setMobileCountryCode(mobile_country_code);
    setMobileNumber(mobile_number);

    verifyOtp({ mobile_country_code, mobile_number, otp });
  };

  useEffect(() => {
    if (otpTimeout === 0) {
      setInfoType(MODAL_INFO_TYPE.OTP_TIMEOUT);
      setOpen(true);
    }
  }, [otpTimeout]);

  useEffect(() => {
    if (otp.length === 4) {
      validateOTP();
    }
  }, [otp]);

  return (
    <>
      <Stack
        sx={{
          minHeight: "70vh",
          color: "text.primary",
          paddingLeft: "16px",
          paddingRight: "16px",
        }}
        justifyContent="space-between"
        spacing={2}
      >
        <div style={{ padding: 8 }}>
          <Navbar
            backIcon
            logo
            languageIcon
            location={state}
            path={state?.path}
            autoRedirect={state?.autoRedirect}
            register={state?.register}
          />
        </div>
        <Stack
          spacing={5}
          sx={{
            p: 3,
          }}
        >
          <Stack>
            <Typography variant="h5" align="center" gutterBottom>
              {t("phoneVerification.heading")}
            </Typography>

            <Typography variant="body1" align="center" gutterBottom>
              {t("phoneVerification.enterOTP")}
            </Typography>
          </Stack>

          <MuiOtpInput
            value={otp}
            onChange={handleChange}
            TextFieldsProps={{
              className: "otp-input",
              type: "number",
              placeholder: "0",
              disabled: otp.length === 4,
              sx: {
                "& .MuiOutlinedInput-root": {
                  "& input": {
                    fontSize: "2rem",
                  },
                  "& input:focus::placeholder ": {
                    color: "transparent",
                  },
                },
              },
            }}
          />

          <Typography variant="body1" align="center" gutterBottom>
            <Trans
              i18nKey={t("phoneVerification.enterCode")}
              values={{
                otpTimeout: otpTimeout,
              }}
            />
          </Typography>

          <Button
            variant="text"
            color="inherit"
            size="medium"
            disableRipple
            disableElevation
            sx={{
              p: 0,
              minHeight: "auto",
              minWidth: "auto",
              textTransform: "none",
              textDecoration: "underline",
              "&:hover": {
                backgroundColor: "inherit",
                textDecoration: "underline",
              },
            }}
            onClick={() => {
              resendOtp({
                mobile_country_code:
                  "+" + searchParams.get("mobile_country_code"),
                mobile_number: searchParams.get("mobile"),
              });
            }}
          >
            {t("phoneVerification.resendOtp")}
          </Button>
        </Stack>

        <Footer showFooterSection={false} />
      </Stack>

      <InfoModal
        open={open}
        setOpen={setOpen}
        infoText={
          infoType === MODAL_INFO_TYPE.INVALID_OTP
            ? t("phoneVerification.wrongOtp")
            : t("phoneVerification.notEnterOtp")
        }
        actionButtonLabel={t("phoneVerification.resendOtp")}
        actionButtonOnClick={() => {
          resendOtp({
            mobile_country_code: "+" + searchParams.get("mobile_country_code"),
            mobile_number: searchParams.get("mobile"),
          });
        }}
      />
    </>
  );
}

export default PhoneNumberVerificationOTP;
