import dayjs from "dayjs";

export const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const getSingleDigit24Hour = (fullTime) => {
  let Hour;
  Hour =
    fullTime?.split(":")[0][0] == "0"
      ? fullTime?.split(":")[0][1]
      : fullTime?.split(":")[0];

  return parseInt(Hour);
};

export const getSingleDigitMinutes = (fullTime) => {
  let minutes;
  minutes = fullTime?.split(":")[1];

  return parseInt(minutes);
};

export const formatDateComponents = (dateString) => {
  if (!dateString) return {};

  // Create a dayjs object from the date string
  const selectedDay = dayjs(dateString);

  // Extract formatted date components
  const formattedDate = selectedDay.format("DD"); // Day of the month
  const formattedDayIndex = selectedDay.format("d"); // Day index (0 for Sunday, 1 for Monday, etc.)
  const formattedDay = selectedDay.format("dddd"); // Full name of the day
  const formattedMonthIndex = selectedDay.format("M"); // Month index (1 for January, 2 for February, etc.)
  const formattedMonth = selectedDay.format("MMMM"); // Full name of the month

  // Return formatted components as an object
  return {
    day: formattedDay,
    dayIndex: formattedDayIndex,
    date: formattedDate,
    month: formattedMonth,
    monthIndex: formattedMonthIndex,
    fullDate: selectedDay,
  };
};

export const getDayNameFromIndex = (index) => {
  const today = dayjs();
  const baseDate = today.startOf("week").add(index, "day");

  const previousDayDate = baseDate.subtract(1, "day");

  return previousDayDate.format("dddd");
};

export const hasSameMonthAndDate = (selected, current) => {
  return (
    selected?.month() === current?.month() && selected?.date() === current?.date()
  );
};