import React from "react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router";
import { useTranslation } from "react-i18next";

import ReceiptItem from "../../components/receipt-items";
import getOrderDetails, {
  GET_ORDER_DETAILS_QUERY_KEY,
} from "../../api/order/getOrderDetails";

import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import {
  ArabicIcon,
  EnglishIcon,
  ArrowIcon,
  ClockIcon,
  OrderSeazenLogo,
  LocationIcon,
} from "../../assets";
import { removeDuplicateItem } from "../../utils/removeDuplicateItems";
import { languageSelector, retrunLanguage } from "../../utils/languageSelect";

import "./style.css";

const Receipt = () => {
  const { i18n, t } = useTranslation("order");

  const navigate = useNavigate();
  const { id } = useParams();

  const handleOrderHelp = () => {
    // phone number
    window.open("tel:+9651818555", "_blank");
  };

  const {
    data: orderDetailsResponse,
    isLoading,
    isError,
    isFetching,
  } = useQuery({
    queryFn: () => {
      return getOrderDetails(id);
    },
    queryKey: [GET_ORDER_DETAILS_QUERY_KEY, id],
  });

  const demo = removeDuplicateItem(orderDetailsResponse?.data?.order_item);
  const calculateTotalVariantPrice = (variantArray) => {
    let total_price = 0;
    for (const variant of variantArray) {
      if (variant.option_quantity === 1) {
        total_price += parseFloat(variant.unit_price);
      }
    }
    return total_price;
  };

  demo.forEach((item) => {
    const totalSidePrice = calculateTotalVariantPrice(item.side_variant);
    const totalToppingPrice = calculateTotalVariantPrice(item.topping_variant);
    const totalCustomPrice = calculateTotalVariantPrice(item.custom_variant);
    const totalSizePrice = calculateTotalVariantPrice(item.size_variant);

    item.totalOfVariants =
      totalSidePrice + totalToppingPrice + totalSizePrice + totalCustomPrice;
  });

  // const sumWithVariantsPrice = demo.map((val) => {
  //   return {
  //     ...val,
  //     unit_price: val.unit_price + val.totalOfVariants,
  //   };
  // });
  const sumWithVariantsPrice = demo.map((val) => {
    return {
      ...val,
      unit_price:
        (val.unit_price_discounted !== ""
          ? Number(val.unit_price_discounted)
          : val.unit_price) + val.totalOfVariants,
    };
  });

  // const getItemsTotal = () => {
  //   if (
  //     orderDetailsResponse &&
  //     orderDetailsResponse.data &&
  //     orderDetailsResponse.data.order_item
  //   ) {
  //     return sumWithVariantsPrice.reduce((acc, item) => {
  //       return acc + Number(item.unit_price) * Number(item.quantity);
  //     }, 0);
  //   }

  //   return Number(0);
  // };

  const returnNameOfBranch = () => {
    const picupType =
      orderDetailsResponse?.data?.store_pickup === 1
        ? t("storePickup")
        : t("carPickup");

    const branchName = retrunLanguage(i18n)
      ? orderDetailsResponse?.data?.branch_name_ar
      : orderDetailsResponse?.data?.branch_name_en;

    return `${branchName} (${picupType})`;
  };

  const returnHouseFormat = () => {
    return `House: ${orderDetailsResponse?.data?.address?.house_no}, Block: ${orderDetailsResponse?.data?.address?.block}, Street: ${orderDetailsResponse?.data?.address?.street}`;
  };

  const returnOfficeFormat = () => {
    return `Company: ${orderDetailsResponse?.data?.address?.company_name}, Block: ${orderDetailsResponse?.data?.address?.block}, Floor: ${orderDetailsResponse?.data?.address?.floor}`;
  };

  const returnAppartmentFormat = () => {
    return `Apartment: ${orderDetailsResponse?.data?.address?.apartment_no}, Block: ${orderDetailsResponse?.data?.address?.block}, Street: ${orderDetailsResponse?.data?.address?.street}`;
  };

  return (
    <div>
      <div className="receipt-nav">
        <IconButton onClick={() => navigate(-1)}>
          <img
            src={ArrowIcon}
            alt=""
            className={retrunLanguage(i18n) ? "rotate" : ""}
          />
        </IconButton>

        <span>
          <img style={{ width: "90px" }} src={OrderSeazenLogo} alt="Logo" />
          <sub className="beta">{t("header.beta", { ns: "translation" })}</sub>
        </span>

        <IconButton
          className="brand-logo"
          onClick={() => {
            languageSelector(i18n);
          }}
        >
          <img src={retrunLanguage(i18n) ? EnglishIcon : ArabicIcon} alt="" />
        </IconButton>
      </div>
      {isLoading || isFetching ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress size={80} color="primary" />
        </Box>
      ) : (
        <>
          <div className="receipt-head">
            <p>{t("yourReceipt")}</p>
            <Button
              variant="contained"
              sx={{ widows: "93px", height: "26px" }}
              onClick={handleOrderHelp}
            >
              {t("orderHelp")}
            </Button>
          </div>
          <div className="receipt-info">
            <div id="heading">
              {orderDetailsResponse?.data?.service_type === "pickup"
                ? t("pickFrom")
                : t("deliverTo")}{" "}
            </div>

            <div>
              <div
                id="location-img"
                className={
                  retrunLanguage(i18n) ? "left-margin" : "right-margin"
                }
              >
                <img src={LocationIcon} alt="" />
              </div>

              <div id="heading-cont">
                {orderDetailsResponse?.data?.service_type === "pickup"
                  ? returnNameOfBranch()
                  : orderDetailsResponse?.data?.address?.address_type ===
                    "house"
                  ? returnHouseFormat()
                  : orderDetailsResponse?.data?.address?.address_type ===
                    "office"
                  ? returnOfficeFormat()
                  : returnAppartmentFormat()}
              </div>
            </div>

            <div>
              <div
                id="clock-img"
                className={
                  retrunLanguage(i18n) ? "left-margin" : "right-margin"
                }
              >
                <img src={ClockIcon} alt="" />
              </div>

              <div id="heading-cont">
                {orderDetailsResponse?.data?.order_date}
              </div>
            </div>
          </div>

          <div>
            {!isLoading &&
              !isError &&
              orderDetailsResponse &&
              orderDetailsResponse.data &&
              orderDetailsResponse.data.order_item &&
              sumWithVariantsPrice.map((item, index) => (
                <ReceiptItem
                  key={index}
                  item={item}
                  currency_code={orderDetailsResponse.data.currency_code}
                  i18n={retrunLanguage(i18n)}
                />
              ))}
          </div>

          <Stack spacing={2} sx={{ color: "#1F2933", padding: "20px 30px" }}>
            <Typography variant="h5" fontWeight={500}>
              {t("summary")}
            </Typography>

            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body1">
                {t("item")}
                {orderDetailsResponse?.data?.order_item?.length > 1
                  ? t("s")
                  : ""}{" "}
                {t("total")}
              </Typography>

              <Typography variant="body1">{`${t("kwd", {
                ns: "common",
              })} ${Number(orderDetailsResponse?.data?.order_sub_total_price).toFixed(3)}`}</Typography>
            </Stack>

            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body1">{t("discount")}</Typography>

              <Typography variant="body1">{`${t("kwd", {
                ns: "common",
              })} -${Number(orderDetailsResponse?.data?.discount_price).toFixed(
                3
              )}`}</Typography>
            </Stack>

            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body1">{t("deliveryCost")}</Typography>

              <Typography variant="body1">
                {`${t("kwd", { ns: "common" })}
                  ${Number(
                    orderDetailsResponse?.data?.delivery_charges
                  ).toFixed(3)}`}
              </Typography>
            </Stack>

            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body1">{t("tip")}</Typography>

              <Typography variant="body1">
                {`${t("kwd", { ns: "common" })}
                  ${Number(orderDetailsResponse?.data?.tip_amount).toFixed(3)}`}
              </Typography>
            </Stack>

            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body1">{t("totalPayment")}</Typography>

              <Typography variant="body1">{`${t("kwd", {
                ns: "common",
              })} ${Number(
                orderDetailsResponse?.data?.order_total_price
              ).toFixed(3)}`}</Typography>
            </Stack>
          </Stack>
        </>
      )}
    </div>
  );
};

export default Receipt;
