import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";

import {
  Skeleton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  Typography,
} from "@mui/material";

import Card from "../../components/adressList";
import MyAddresses from "../../components/header/myAdresses";
import { AddressesButton } from "../../components/adressList/addressesButton";
import RemoveFromCart from "../../components/dialog/differentBranchRestaurant";

import getAllAddress from "../../api/address/getAddress";
import deleteAddressApiCall from "../../api/address/deleteAddress";
import getBranchByAreaId from "../../api/restaurant/branches/getBranchIdBasedOnAreaId";
import menuItemsAvailability from "../../api/menu/menuItemsAvailability";
import deleteCart from "../../api/cart/deleteCartItem";

import { getAddress } from "../../store/reducers/addressSlice";
import { addSelectedAddress } from "../../store/reducers/userLocationSlice";
import { removeFromCart } from "../../store/reducers/cartSlice";

import { getFullAddress } from "../../utils/getAddress";

import { paths } from "../../routes/paths";
import { Trans, useTranslation } from "react-i18next";
import { retrunLanguage } from "../../utils/languageSelect";
import {
  AppartBlackIcon,
  ExclaimIcon,
  HomeBlackIcon,
  LocationIcon,
  OfficeBlackIcon,
} from "../../assets";

import "./style.css";

const App = () => {
  const { i18n, t } = useTranslation("address");

  const isToken = localStorage.getItem("token");
  const userSelected = localStorage.getItem("selected");
  const country = JSON.parse(localStorage.getItem("country"));
  const restaurantID = localStorage.getItem("restaurantID");

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const addressData = useSelector((state) => state.address.addressData);
  const resturantsData = useSelector(
    (state) => state.resturants.resturantsData
  );
  const cart = useSelector((state) => state.cart.cart);

  const [open, setOpen] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [addressType, setAddressType] = useState("");
  const [addressId, setAddressId] = useState(null);
  const [fullAddress, setFullAddress] = useState(null);
  const [deleteAdd, setDeleteAdd] = useState(false);
  const [addressToBeDeleted, setAddressToBeDeleted] = useState({});
  const [isLoading, setIsloading] = useState(false);
  const [delivery_charges, setDeliveryCharge] = useState();
  const [delivery_time, setDeliveryTime] = useState();
  const [name_en, setNameEn] = useState();
  const [restName, setRestName] = useState();
  const [itemsAfterRemove, setItemsAfterRemove] = useState([]);
  const [clearCartModal, setClearCartModal] = useState(false);
  const [removingItem, setRemovingItem] = useState(false);
  const [changeAddressDetails, setChangeAddressDetails] = useState(null);
  const [dispatchCount, setDispatchCount] = useState(0);

  const addRestauantName = (id) => {
    const restName = resturantsData?.find((rest) => rest.id === id);

    return restName?.name_en;
  };

  const fetchFunction = async () => {
    setIsloading(true);
    getAllAddress(isToken).then((res) => {
      if (res.success === "ok") {
        const filterAddress = res.data.filter(
          (address) => !Array.isArray(address.delivery_charge_area_info)
        );

        dispatch(getAddress(filterAddress));

        setIsloading(false);
        setDeleteAdd(false);
        setOpen(false);
      }
    });
  };

  useEffect(() => {
    fetchFunction();
  }, []);

  const { refetch } = useQuery({
    queryFn: () => getAllAddress(isToken),
    onSuccess: (data) => {
      const filterAddress = data.data.filter(
        (address) => !Array.isArray(address.delivery_charge_area_info)
      );

      dispatch(getAddress(filterAddress));

      setIsloading(false);
      setDeleteAdd(false);
      setOpen(false);
    },
    enabled: false,
  });

  const changeAddressAfterSelect = (item) => {
    getBranchByAreaId(
      location?.state?.path === "/cart"
        ? +restaurantID
        : item.delivery_charge_area_info?.restaurant_id,
      country.id,
      item?.delivery_charge_area_name_en,
      dispatch
    )
      .then(() => {
        setSelectedId(item.id);
        setDeliveryCharge(
          item?.delivery_charge_area_info?.delivery_charges?.split(" ")[1]
        );
        setDeliveryTime(item?.delivery_charge_area_info?.delivery_time);
        // setMinOrderAmount(item?.delivery_charge_area_info?.minimum_order_amount);
        setNameEn(item.delivery_charge_area_info?.name_en);
        setRestName(
          addRestauantName(item.delivery_charge_area_info?.restaurant_id)
        );

        localStorage.setItem("area_id", item?.delivery_charge_area_info.id);
        const matchingArea = {
          area_name_en: item.delivery_charge_area_info?.name_en,
          area_name_ar: item.delivery_charge_area_info.name_ar,
        };

        localStorage.setItem("matchingArea", JSON.stringify(matchingArea));

        const selectedAddress = {
          lat: +item.latitude,
          lng: +item.longitude,
        };

        getFullAddress(
          selectedAddress.lat,
          selectedAddress.lng,
          setFullAddress
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const selectCurrentAddress = (item) => {
    if (cart?.length > 0) {
      const itemIds = cart?.map((item) => {
        return item.id;
      });

      const availabilityObject = {
        restaurant_id: item.delivery_charge_area_info.restaurant_id,
        branch_id: item.delivery_charge_area_info.branch_id,
        item_ids: itemIds,
      };

      menuItemsAvailability(availabilityObject, isToken).then((itemRes) => {
        if (itemRes?.data?.unavailable_item_ids?.length > 0) {
          const removeFromCart = cart?.filter((item) =>
            itemRes?.data?.unavailable_item_ids.includes(item.id)
          );

          setItemsAfterRemove(removeFromCart);

          setClearCartModal(true);
          setChangeAddressDetails({
            address: item,
          });
        } else {
          changeAddressAfterSelect(item);
        }
      });
    } else {
      changeAddressAfterSelect(item);
    }
  };

  useEffect(() => {
    if (fullAddress !== null) {
      const addToStorage = {
        ...fullAddress,
        addressId: selectedId,
        delivery_charges,
        delivery_time,
        // minOrderAmount,
        name_en,
        restaurantName: restName,
      };

      localStorage.setItem("selected", JSON.stringify(addToStorage));
      dispatch(addSelectedAddress(addToStorage));

      if (location?.state?.path === "/cart") {
        navigate(paths.cart, {
          state: { back: "back-to-cart", ...location?.state },
        });
      } else if (
        location?.state?.path === "/profile" ||
        location?.state?.path === "/address"
      ) {
        navigate(paths.home);
      }
    }
  }, [fullAddress, location]);

  const deselectCurrentAddress = () => {
    setIsSelected(false);
  };

  const deleteAddress = (id) => {
    setDeleteAdd(true);
    deleteAddressApiCall(id, isToken).then((res) => {
      if (res.statusCode === 200) {
        const selected = JSON.parse(userSelected);

        if (addressToBeDeleted && selected !== null) {
          const lat = selected.lat;
          const lng = selected.lng;

          const deleteLat = +addressToBeDeleted?.latitude;
          const deleteLng = +addressToBeDeleted?.longitude;

          if (
            parseFloat(lat) === parseFloat(deleteLat) &&
            parseFloat(lng) === parseFloat(deleteLng)
          ) {
            localStorage.removeItem("selected");
            localStorage.removeItem("matchingArea");
            localStorage.removeItem("area_id");
          }
        }

        refetch();
      }
    });
  };

  const handleClose = () => {
    setOpen(false);
    setAddressType("");
    setAddressId(null);
  };

  const handleClickOpen = (item) => {
    setAddressType(item.address_type);
    setAddressId(item.id);
    setSelectedId(item.id);
    setOpen(true);
    setAddressToBeDeleted(item);
  };

  const clearFunction = () => {
    setRemovingItem(true);

    itemsAfterRemove?.map((item) => {
      deleteCart(
        {
          cart_id: item.cart_id,
          item_id: item.id,
          item_code: item.item_code,
        },
        isToken
      )
        .then(() => {
          dispatch(removeFromCart(item.id));

          setDispatchCount((prevCount) => prevCount + 1);
        })
        .catch(() => {
          setRemovingItem(false);
        });
    });
  };

  useEffect(() => {
    if (
      dispatchCount === itemsAfterRemove?.length &&
      changeAddressDetails?.address !== undefined
    ) {
      setRemovingItem(false);

      changeAddressAfterSelect(changeAddressDetails?.address);

      setClearCartModal(false);
    }
  }, [dispatchCount, itemsAfterRemove]);
  console.log(location?.state, "navigationState");
  return (
    <div className="app">
      <MyAddresses
        title={t("heading")}
        path={location?.state?.path}
        fromAllAddresses={true}
        fromMenu={location?.state?.fromMenu}
        fromHome={location?.state?.fromHome}
        fromDetail={location?.state?.fromDetail}
        detailPath={location?.state?.detailPath}
        backToCart={location?.state?.backToCart}
        backCart={location?.state?.backCart}
        editLocation={location?.state?.editLocation}
      />
      {isLoading ? (
        Array.from({ length: 3 }).map((_) => (
          <>
            <div className="skeleton-container container-fluid">
              <div className="skeleton-header">
                <div>
                  <Skeleton
                    className={`skeleton-title ${
                      retrunLanguage(i18n) ? "skeleton-title-ar" : ""
                    }`}
                    variant="text"
                    width={50}
                    height={20}
                  />
                  <Skeleton
                    className={`skeleton-title2 ${
                      retrunLanguage(i18n) ? "skeleton-title-ar" : ""
                    }`}
                    variant="text"
                    width={50}
                    height={20}
                  />
                </div>
                <div
                  className={`skeleton-button ${
                    retrunLanguage(i18n) ? "skeleton-button-ar" : ""
                  }`}
                >
                  <Skeleton
                    className={`skeleton-btn ${
                      retrunLanguage(i18n) ? "skeleton-btn-ar" : ""
                    }`}
                    variant="text"
                    width={"30%"}
                    height={45}
                  />
                  <Skeleton variant="text" width={"30%"} height={45} />
                </div>
              </div>
              <Skeleton
                className="skeleton-dots"
                variant="text"
                width={"90%"}
                height={2}
              />

              <div className="skeleton-detail">
                <div>
                  <Skeleton
                    className={`skeleton-title ${
                      retrunLanguage(i18n) ? "skeleton-title-ar" : ""
                    }`}
                    variant="text"
                    width={50}
                    height={20}
                  />
                  <Skeleton
                    className={`skeleton-title2 ${
                      retrunLanguage(i18n) ? "skeleton-title-ar" : ""
                    }`}
                    variant="text"
                    width={70}
                    height={20}
                  />
                  <Skeleton
                    className={`skeleton-title ${
                      retrunLanguage(i18n) ? "skeleton-title-ar" : ""
                    }`}
                    variant="text"
                    width={60}
                    height={20}
                  />
                  <Skeleton
                    className={`skeleton-title2 ${
                      retrunLanguage(i18n) ? "skeleton-title-ar" : ""
                    }`}
                    variant="text"
                    width={60}
                    height={20}
                  />
                </div>

                <div>
                  <Skeleton
                    className={`skeleton-rectangular ${
                      retrunLanguage(i18n) ? "skeleton-rectangular-ar" : ""
                    }`}
                    variant="rectangular"
                  />
                </div>
              </div>
            </div>
          </>
        ))
      ) : (
        <div className="parent-address">
          {addressData?.length ? (
            addressData?.map((data) => (
              <div key={data.id}>
                <Card
                  iconUrl={
                    data.address_type === "house"
                      ? HomeBlackIcon
                      : data.address_type === "apartment"
                      ? AppartBlackIcon
                      : OfficeBlackIcon
                  }
                  data={data}
                  setOpen={setOpen}
                  open={open}
                  deleteAddress={deleteAddress}
                  selectCurrentAddress={selectCurrentAddress}
                  deselectCurrentAddress={deselectCurrentAddress}
                  isSelected={isSelected}
                  handleClickOpen={handleClickOpen}
                  setAddressToBeDeleted={setAddressToBeDeleted}
                  location={location}
                />
              </div>
            ))
          ) : (
            <div className="no-address">
              <div className="location-icon">
                <img src={LocationIcon} />
              </div>

              <Typography variant="h6" className="empty-text">
                {t("noAddresses")}
              </Typography>
            </div>
          )}
        </div>
      )}
      <AddressesButton
        restaurantName={location?.state?.restaurantName}
        restaurant_id={location?.state?.restaurant_id}
        branch_id={location?.state?.branch_id}
        path={location?.state?.path}
        location={location}
        isLoading={isLoading}
        t={t}
      />

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="delete-modal"
        sx={{
          overflow: "hidden",
          "& .MuiDialog-paper": {
            height: "204px",
            width: "327px",
            textAlign: "center",
            overflow: "hidden",
            overflowY: "hidden",

            "@media (min-width: 600px)": {
              left: "0",
              position: "fixed",
              margin: "20px",
            },
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <img src={ExclaimIcon}></img>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div
              style={{
                font: "normal normal bold 16px/18px Titillium Web",
                color: "#959FA8",
                whiteSpace: "pre-line",
              }}
              className="delete-para"
            >
              <Trans
                i18nKey={t("deleteAddress", { ns: "dialog" })}
                values={{
                  addressType: addressType,
                }}
              />
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            marginRight: retrunLanguage(i18n) ? "0px !important" : "35px",
            marginLeft: retrunLanguage(i18n) ? "35px !important" : "0px",
            marginBottom: "20px",
          }}
        >
          <Button
            onClick={handleClose}
            sx={{
              background: "#FFFFFF 0% 0% no-repeat padding-box",
              border: "1px solid #98694E",
              borderRadius: "12px",
              opacity: "1",
              width: "116px",
              height: "29px",
            }}
          >
            <span
              style={{
                font: "normal normal 600 16px/24px Titillium Web",
                color: "#98694E",
              }}
            >
              {t("back", { ns: "dialog" })}
            </span>
          </Button>

          <Button
            color="primary"
            autoFocus
            className={deleteAdd ? "loader-button" : "delete-button"}
            sx={{
              background: "#98694E 0% 0% no-repeat padding-box",
              border: "1px solid #707070",
              borderRadius: "12px",
              opacity: "1",
              width: "116px",
              height: "29px",
              transition:
                "background-color 0.3s ease, color 0.3s ease, border 0.3s ease",
              marginRight: retrunLanguage(i18n) ? "8px !important" : "0px",
              marginLeft: retrunLanguage(i18n) ? "0px !important" : "8px",
            }}
            onClick={() => deleteAddress(addressId)}
            disabled={deleteAdd}
          >
            {deleteAdd ? (
              <CircularProgress className="loader" />
            ) : (
              <span
                style={{
                  font: "normal normal 600 16px/24px Titillium Web",
                  color: "#ffffff",
                }}
              >
                {t("delete", { ns: "common" })}
              </span>
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <RemoveFromCart
        clearCartModal={clearCartModal}
        setClearCartModal={setClearCartModal}
        textToShow={t("warningOrderChange", { ns: "dialog" })}
        clearFunction={clearFunction}
        itemsAfterRemove={itemsAfterRemove}
        removingItem={removingItem}
      />
    </div>
  );
};

export default App;
