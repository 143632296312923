export const generateGuestsOptions = (guestAllowed) => {
  const options = [];
  const limit = parseInt(guestAllowed);

  for (let i = 1; i <= limit; i += 1) {
    const value = i.toString().padStart(2, "0");
    options.push({ value, label: value });
  }

  return options;
};

export const getSeatingAreaOptions = (seatingType) => {
  const seatingAreaOptions = [
    { value: "any", label: "Any" },
    { value: "inside", label: "Inside" },
    { value: "outside", label: "Outside" },
  ];

  if (seatingType === "both") {
    return seatingAreaOptions; // Return all options
  }

  return seatingAreaOptions.filter((option) => option.value === "inside"); // Return only "inside"
};
