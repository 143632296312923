import { parse, format, isValid } from "date-fns";

export const convertTo24Hour = (dateStr, timeStr) => {
  // First, parse the `dateStr` from 'dd MMM yy' to a valid Date object
  const parsedDate = parse(dateStr, "dd MMM yy", new Date());

  // Format the parsed date into 'yyyy-MM-dd' format
  const formattedDate = format(parsedDate, "yyyy-MM-dd");

  // Split the time string into time and AM/PM modifier
  const [time, modifier] = timeStr.split(" ");
  let [hours, minutes] = time.split(":");

  // Convert the hours based on the AM/PM modifier
  if (modifier.toLowerCase() === "pm" && hours !== "12") {
    hours = String(parseInt(hours, 10) + 12);
  } else if (modifier.toLowerCase() === "am" && hours === "12") {
    hours = "00"; // 12 AM should be converted to 00:00 in 24-hour format
  }

  // Ensure hours and minutes are in two digits
  hours = hours.padStart(2, "0");
  minutes = minutes.padStart(2, "0");

  // Return a properly formatted Date object
  return new Date(`${formattedDate}T${hours}:${minutes}:00`);
};

export const convertDateFormat = (dateString) => {
  const parsedDate = parseDate(dateString);
  // Return the date formatted as 'dd MMM yy'
  return format(parsedDate, "dd MMM yy");
};

const parseDate = (dateString) => {
  let parsedDate;
  console.log(dateString, "ShahdateString");
  // Check if the date is in "yyyy-MM-dd" format (e.g., "2024-10-10")
  if (dateString.includes("-") && dateString.split("-")[0].length === 4) {
    parsedDate = parse(dateString, "yyyy-MM-dd", new Date());
  }
  // Check if the date is in "dd-MM-yyyy" format (e.g., "10-10-2024")
  else if (dateString.includes("-") && dateString.split("-")[0].length === 2) {
    parsedDate = parse(dateString, "dd-MM-yyyy", new Date());
  }
  // Otherwise assume it's in "dd MMM yy" format (e.g., "10 Oct 24")
  else {
    parsedDate = parse(dateString, "dd MMM yy", new Date());
  }

  // Check if the parsed date is valid before formatting
  if (!isValid(parsedDate)) {
    parsedDate = parse(dateString, "MM-dd-yyyy", new Date());
  }

  if (!isValid(parsedDate)) {
    throw new Error("Invalid date format");
  }

  return parsedDate;
};

export const removePastDateData = (data) => {
  const filteredPastData = data.filter((elem) => {
    let dateStr = elem?.queue_date;

    // If it's a reservation, safely handle and convert the reservation_date
    if (!dateStr && elem?.reservation_date) {
      const reservationDate = elem.reservation_date;
      if (reservationDate) {
        const [month, day, year] = reservationDate.split("-");
        dateStr = `${day}-${month}-${year}`;
      }
    }

    // Continue with the date comparison logic
    const elemDate = new Date(dateStr?.split("-").reverse().join("-"));
    return elemDate >= new Date().setHours(0, 0, 0, 0);
  });

  return filteredPastData;
};

export const getReservationDateTime = (reservation) => {
  const parsedDate = parseDate(reservation?.reservation_date);
  const parsedTime = parse(reservation?.reservation_time, "h:mm a", new Date());
  return new Date(
    parsedDate.setHours(parsedTime.getHours(), parsedTime.getMinutes())
  );
};
