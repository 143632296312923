import React, { Suspense, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import { Box } from "@mui/material";

import Navbar from "../../components/header/index";
import Slider from "../../components/carousel/slider/index";
import LazyLoadSlider from "../../components/draggableSlider/lazyLoadSlider";
import RestaurantsOrderTypeButton from "../../components/category/button";
import countryList from "../../api/common/getAllCountries";
import getBranchByAreaId from "../../api/restaurant/branches/getBranchIdBasedOnAreaId";
import menuItemsAvailability from "../../api/menu/menuItemsAvailability";
import deleteCart from "../../api/cart/deleteCartItem";

import getAllPopularItems, {
  GET_ALL_POPULAR_ITEMS_QUERY_KEY,
} from "../../api/home/getAllPopularItems";

import getIftarItems, {
  GET_IFTAR_ITEMS_QUERY_KEY,
} from "../../api/home/getIftarItems";
import getGatheringItems, {
  GET_GATHERING_ITEMS_QUERY_KEY,
} from "../../api/home/getGatheringItems";
import supportDeliveryByArea from "../../api/home/supportDeliveryByArea";
import getAllRestaurantsBranches from "../../api/home/getAllRestaurantsBranches";
import getAllAddress from "../../api/address/getAddress";

import { addCountry } from "../../store/reducers/authSlice";
import {
  getAllBranches,
  getBranchHours,
  getSupportResturants,
  removeCategoryItemList,
  removeCategoryList,
  removeResturantsDetails,
  resetSupportResturants,
} from "../../store/reducers/resturantsSlice";
// import { getAreas } from "../../store/reducers/addressSlice";
import { addSelectedAddress } from "../../store/reducers/userLocationSlice";
import { removeFromCart } from "../../store/reducers/cartSlice";
import { getAddress } from "../../store/reducers/addressSlice";

import { filterPickupRestaurants } from "../../utils/filterPickupRestaurant";
import { CartIcon } from "../../assets";
import { paths } from "../../routes/paths";
import {
  // flattenArray,
  getDistanceFromLatLonInKm,
  getFullAddress,
  selectChooseAddress,
} from "../../utils/getAddress";
import {
  addBrownClasses,
  addClasses,
  removeBrownClasses,
  removeClasses,
} from "../../utils/homeScreenUtils";

import "../../components/carousel/slider/style.css";
import "./style.css";
import LazyLoadFooter from "../../components/footer/lazyloadFooter";

const AddressDialog = React.lazy(() => import("./addressDialog"));
const SelectLocation = React.lazy(() =>
  import("../../components/dialog/selectLocation")
);
const DialogComponent = React.lazy(() => import("../../components/dialog"));
const SchedulePicker = React.lazy(() =>
  import("../../components/shared/schedulePicker")
);
const ScheduleDialog = React.lazy(() =>
  import("../../components/dialog/scheduleDialog")
);
const RemoveFromCart = React.lazy(() =>
  import("../../components/dialog/differentBranchRestaurant")
);
const PickupFrom = React.lazy(() =>
  import("../../components/dialog/pickupDialog")
);

const Home = () => {
  const { t } = useTranslation("landingPage");

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const addressData = useSelector((state) => state.address.addressData);
  const branchHours = useSelector((state) => state.resturants.branchHours);
  // const pickupBranchId = useSelector(
  //   (state) => state.resturants.pickupBranchId
  // );
  // const allAreas = useSelector((state) => state.address.allAreas);
  const resturantsData = useSelector(
    (state) => state.resturants.resturantsData
  );
  const cart = useSelector((state) => state.cart.cart);
  const supportRestaurant = useSelector(
    (state) => state.resturants.supportRestaurant
  );
  const allBranches = useSelector((state) => state.resturants.allBranches);

  const token = localStorage.getItem("token");
  const userSelected = localStorage.getItem("selected");
  const userLocation = localStorage.getItem("location");
  const isCountry = localStorage.getItem("country");
  const matchingArea = JSON.parse(localStorage.getItem("matchingArea"));

  const [selectedAddress, setSelectedAddress] = useState(null);
  const [addressId, setAddressID] = useState();
  const [delivery_charges, setDeliveryCharge] = useState();
  const [delivery_time, setDeliveryTime] = useState();
  const [name_en, setNameEn] = useState();
  const [restName, setRestName] = useState("");
  const [openAddressPopup, setOpenAddressPopup] = useState(false);
  const [filterClassNear, setToggleFilterClassNear] = useState(false);
  const [filterSelectedNow, setFilterSelectedNow] = useState("");
  const [filterClassDelivery, setToggleFilterClassDelivery] = useState(false);
  const [fullAddress, setFullAddress] = useState(null);
  const [restaurantId, setRestaurantId] = useState(null);
  const [restaurantName, setRestaurantName] = useState("");
  const [distanceIsFar, setDistanceIsFar] = useState(false);
  const [fromPopular, setFromPopular] = useState(false);
  const [popularId, setPopularId] = useState(null);
  const [branchId, setBranchId] = useState(null);
  const [newText, setNewText] = useState("");
  const [selectedText, setSelectedText] = useState("");
  const [openSchedulePopup, setOpenSchedulePopup] = useState(false);
  const [showTimePicker, setShowTimePicker] = useState(false);
  const [restaurantOpen, setRestaurantOpen] = useState(false);
  const [chooseFullAddress, setChooseFullAddress] = useState(null);
  const [openAddressToChoose, setOpenAddressToChoose] = useState(null);
  const [clearCartModal, setClearCartModal] = useState(false);
  const [removingItem, setRemovingItem] = useState(false);
  const [changeAddressDetails, setChangeAddressDetails] = useState(null);
  const [itemsAfterRemove, setItemsAfterRemove] = useState([]);
  const [dispatchCount, setDispatchCount] = useState(0);
  const [pickupFromDialog, setPickupFromDialog] = useState(false);

  const [typesState, setTypesState] = useState({
    dinein: false,
    catering: false,
    delivery: false,
    pickup: false,
  });
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [isLastSliderLoaded, setIsLastSliderLoaded] = useState(false);

  const parentDiv = document?.getElementById("parent-of-all");
  const parentIcon = document?.getElementsByClassName("inner-svg-container");
  const divs = parentDiv?.getElementsByClassName("restaurantCards");
  const ranOnceRef = useRef(false);

  const addRestauantName = (id) => {
    const restName = resturantsData?.find((rest) => rest.id === id);

    return restName?.name_en;
  };

  const fetchAllCountries = () => {
    countryList()
      .then((res) => {
        const defaultCountry = res.data.find((val) => val.is_default == "Yes");

        localStorage.setItem("country", JSON.stringify(defaultCountry));
        dispatch(addCountry(defaultCountry));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchFunctionAddresses = async () => {
    getAllAddress(token).then((res) => {
      if (res.success === "ok") {
        const filterAddress = res.data.filter(
          (address) => !Array.isArray(address.delivery_charge_area_info)
        );

        dispatch(getAddress(filterAddress));
      }
    });
  };

  const fetchAllBranches = () => {
    getAllRestaurantsBranches().then((res) => {
      dispatch(getBranchHours(res?.data));
      dispatch(getAllBranches(res.data));
    });
  };

  const extractSelectedFilter = () => {
    // Use Object.entries() to get an array of [key, value] pairs
    const entry = Object.entries(typesState).find(([key, value]) => value);

    // If a true key-value pair is found, return the key; otherwise, return null
    return entry ? entry[0] : null;
  };

  const fetchSupportRestaurantByArea = (serviceType) => {
    supportDeliveryByArea(
      matchingArea?.area_name_en,
      serviceType?.toLowerCase(),
      token
    ).then((res) => {
      dispatch(getSupportResturants(res?.data));
    });
  };
  const chooseAddress = (item) => {
    selectChooseAddress(
      item,
      setAddressID,
      setDeliveryCharge,
      setDeliveryTime,
      setNameEn,
      setRestName,
      getFullAddress,
      setFullAddress,
      addRestauantName
    );
  };

  const changeAddressAfterSelect = (item) => {
    getBranchByAreaId(
      cart[0]?.restaurant_id,
      JSON.parse(localStorage.getItem("country"))?.id,
      item?.delivery_charge_area_name_en,
      dispatch
    );

    selectChooseAddress(
      item,
      setAddressID,
      setDeliveryCharge,
      setDeliveryTime,
      setNameEn,
      setRestName,
      getFullAddress,
      setChooseFullAddress,
      addRestauantName
    );
  };

  const onChooseFullAddress = (item) => {
    if (cart?.length > 0) {
      const itemIds = cart?.map((item) => {
        return item.id;
      });

      const availabilityObject = {
        restaurant_id: item.delivery_charge_area_info.restaurant_id,
        branch_id: item.delivery_charge_area_info.branch_id,
        item_ids: itemIds,
      };

      menuItemsAvailability(availabilityObject, token).then((itemRes) => {
        if (itemRes?.data?.unavailable_item_ids?.length > 0) {
          const removeFromCart = cart?.filter((item) =>
            itemRes?.data?.unavailable_item_ids.includes(item.id)
          );

          setItemsAfterRemove(removeFromCart);

          setClearCartModal(true);
          setChangeAddressDetails({
            address: item,
          });
        } else {
          changeAddressAfterSelect(item);
        }
      });
    } else {
      changeAddressAfterSelect(item);
    }
  };

  const clearFunction = () => {
    setRemovingItem(true);

    itemsAfterRemove?.map((item) => {
      deleteCart(
        {
          cart_id: item.cart_id,
          item_id: item.id,
          item_code: item.item_code,
        },
        token
      )
        .then(() => {
          dispatch(removeFromCart(item.id));

          setDispatchCount((prevCount) => prevCount + 1);
        })
        .catch(() => {
          setRemovingItem(false);
        });
    });
  };

  const checkNearByRest = () => {
    let addToStore = {
      ...chooseFullAddress,
      addressId,
    };

    if (name_en) {
      addToStore = {
        ...addToStore,
        delivery_charges,
        delivery_time,
        name_en,
        restaurantName: restName,
      };
    }

    localStorage.setItem("selected", JSON.stringify(addToStore));
    dispatch(addSelectedAddress(addToStore));

    const restaurant = resturantsData.find(
      (res) => res.id === cart[0]?.restaurant_id
    );

    const filterRestaurant = branchHours.filter(
      (branch) => branch.restaurant_id === restaurant.id
    );

    if (filterRestaurant?.length === 0) {
      setRestaurantName(restaurant?.name_en);
      setDistanceIsFar(true);
      setOpenAddressToChoose(false);
    } else {
      if (localStorage.getItem("isAsap") === "true") {
        for (let i = 0; i < filterRestaurant?.length; i++) {
          const date = new Date();
          const options = { weekday: "long" };
          const dayName = date.toLocaleDateString("en-US", options);

          const findStatus = filterRestaurant[i]?.branch_hours?.filter(
            (branch) => branch.week_day === dayName
          );

          if (findStatus[0]?.openingStatus === "Open") {
            setRestaurantOpen(false);
            navigate(paths?.cart, {
              state: { fromHome: true, path: "/home" },
            });

            break;
          } else {
            setRestaurantOpen(true);
          }
        }
      } else {
        setRestaurantOpen(false);
        navigate(paths?.cart, {
          state: { fromHome: true, path: "/home" },
        });
      }
    }
  };

  const checkWithBranchIdDeliverZonesChoose = (allDistancesLessThan50) => {
    // Find the smallest distance and associated location
    const smallestDistance = Math.min(
      ...allDistancesLessThan50.map((item) => item.distance)
    );
    const smallestDistanceLocation = allDistancesLessThan50.find(
      (item) => item.distance === smallestDistance
    );

    if (smallestDistanceLocation) {
      setDistanceIsFar(false);
      setOpenAddressToChoose(false);

      checkNearByRest();
    }
  };

  const checkWithBranchIdDeliverZonesFullAddress = (allDistancesLessThan50) => {
    // Find the smallest distance and associated location
    const smallestDistance = Math.min(
      ...allDistancesLessThan50.map((item) => item.distance)
    );
    const smallestDistanceLocation = allDistancesLessThan50.find(
      (item) => item.distance === smallestDistance
    );

    if (smallestDistanceLocation) {
      setNewText("");
      setDistanceIsFar(false);
      setOpenAddressPopup(false);

      let addToStore = {
        ...fullAddress,
        addressId,
      };

      if (name_en) {
        addToStore = {
          ...addToStore,
          delivery_charges,
          delivery_time,
          // minOrderAmount,
          name_en,
          restaurantName: restName,
        };
      }

      localStorage.setItem("selected", JSON.stringify(addToStore));
      dispatch(addSelectedAddress(addToStore));

      if (restaurantId === null) {
        setOpenAddressPopup(false);
      }

      if (restaurantId !== null && !fromPopular) {
        goToRestaurantMenuPage(restaurantId);
      }

      if (fromPopular && popularId !== null) {
        navigate(paths.details + "/" + popularId, {
          state: {
            id: popularId,
            loc: "popular",
            restaurant_id: restaurantId,
            branch_id: branchId,
          },
        });
      }
    }
  };

  const navigateUser = () => {
    const restaurant = resturantsData.find((res) => res.id === restaurantId);

    navigate(paths.location, {
      state: {
        path: "/home",
        restaurant_id: restaurantId,
        restaurantName: restaurant?.name_en,
        filterSelectedNow:
          filterSelectedNow !== "" && filterSelectedNow === t("nearMe")
            ? selectedFilter
            : "",
      },
    });
  };

  const onNavigateUser = () => {
    const restaurant = resturantsData.find(
      (res) => res.id === cart[0]?.restaurant_id
    );

    navigate(paths.location, {
      state: {
        path: "menu/restaurant",
        restaurant_id: cart[0]?.id,
        restaurantName: restaurant?.name_en,
        addNew: true,
      },
    });
  };

  const handleClose = () => {
    setOpenAddressPopup(false);
    setToggleFilterClassNear(!filterClassNear);
    setToggleFilterClassDelivery(!filterClassDelivery);
    setFilterSelectedNow("");
    setDistanceIsFar(false);
    setFromPopular(false);
    setNewText("");
    removeClasses(divs, parentIcon);
  };

  const restaurantClickScript = (resturantsData) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push({
      event: "productClick",
      ecommerce: {
        click: {
          actionField: { list: "Search Results" }, // Optional list property.
          products: [
            {
              name: resturantsData.name_en, // Name or ID is required.
              id: resturantsData.id,
            },
          ],
        },
      },
    });
  };

  const goToRestaurantMenuPage = (id) => {
    restaurantClickScript(resturantsData);
    localStorage.setItem("restaurantID", id);
    navigate(paths.menu + `/restaurant/${id}`);
  };

  const anyStateTrue = (stateObject) => {
    return Object.values(stateObject).some((state) => state === true);
  };

  // Memoize repeated props for LazyLoadSlider components
  const sliderProps = useMemo(
    () => ({
      setPopularId: setPopularId,
      setBranchId: setBranchId,
      setRestaurantId: setRestaurantId,
    }),
    [setPopularId, setBranchId, setRestaurantId]
  );

  // Function to handle branch hours distance calculations
  const handleBranchHoursDistance = () => {
    const pushedWithDistance = [];

    if (branchHours.length) {
      for (const branch of branchHours) {
        const resLat = +branch?.latitude;
        const resLng = +branch?.longitude;

        const distance = getDistanceFromLatLonInKm(
          resLat,
          resLng,
          fullAddress.lat,
          fullAddress.lng
        );

        if (distance > 50) {
          const restaurant = resturantsData.find(
            (res) => res.id === restaurantId
          );
          if (restaurant) {
            setNewText(t("newLocation"));
            setRestaurantName(restaurant.name_en);
            setDistanceIsFar(true);
            setOpenAddressPopup(false);
          }
          return; // Exit loop as the distance is beyond 50 km
        } else {
          pushedWithDistance.push({ ...branch, distance });
          checkWithBranchIdDeliverZonesFullAddress(pushedWithDistance);
          break; // Stop further iteration after the first match within 50 km
        }
      }
    }
  };

  // Function to handle address selection and storage
  const handleAddressSelection = () => {
    const addToStore = {
      ...fullAddress,
      addressId,
      ...(name_en && {
        delivery_charges,
        delivery_time,
        name_en,
        restaurantName: restName,
      }),
    };

    localStorage.setItem("selected", JSON.stringify(addToStore));
    dispatch(addSelectedAddress(addToStore));
    setOpenAddressPopup(false);

    if (filterSelectedNow?.toLowerCase() === "near me") {
      navigate(paths.nearMe);
    }
  };

  // Function to handle setting address when the cart is empty
  const handleEmptyCartAddress = () => {
    const addToStore = {
      ...chooseFullAddress,
      addressId,
      delivery_charges,
      delivery_time,
      name_en,
      restaurantName: restName,
    };

    localStorage.setItem("selected", JSON.stringify(addToStore));
    dispatch(addSelectedAddress(addToStore));
    navigate(paths.cart);
  };

  // Function to handle distance check and setting distance state
  const handleDistanceCheck = (filteredBranches) => {
    const pushedWithDistance = [];
    const restaurant = resturantsData.find(
      (res) => res.id === cart[0]?.restaurant_id
    );

    for (const branch of filteredBranches) {
      const resLat = +branch.latitude;
      const resLng = +branch.longitude;

      const distance = getDistanceFromLatLonInKm(
        resLat,
        resLng,
        chooseFullAddress.lat,
        chooseFullAddress.lng
      );

      if (distance > 50) {
        setRestaurantName(restaurant?.name_en);
        setDistanceIsFar(true);
        setOpenAddressToChoose(false);
        return; // Exit early if distance is too far
      } else {
        pushedWithDistance.push({ ...branch, distance });
        checkWithBranchIdDeliverZonesChoose(pushedWithDistance);
        break; // Stop further iteration after finding a match within 50 km
      }
    }
  };

  // Helper function to set address and delivery details
  const setMatchingAddressDetails = (address) => {
    const { id, delivery_charge_area_info, latitude, longitude } = address;

    setAddressID(id);

    if (delivery_charge_area_info) {
      const { delivery_charges, delivery_time, name_en, restaurant_id } =
        delivery_charge_area_info;

      setDeliveryCharge(delivery_charges?.split(" ")[1]);
      setDeliveryTime(delivery_time);
      setNameEn(name_en);
      setRestName(addRestauantName(restaurant_id));
    }

    // Store matching area in localStorage
    const matchingArea = {
      area_name_en: delivery_charge_area_info?.name_en,
      area_name_ar: delivery_charge_area_info?.name_ar,
    };

    localStorage.setItem("area_id", delivery_charge_area_info?.id);
    localStorage.setItem("matchingArea", JSON.stringify(matchingArea));

    // Retrieve and set full address
    getFullAddress(+latitude, +longitude, setSelectedAddress);
  };

  useEffect(() => {
    if (!userLocation || userSelected !== null) return;

    const parsedLocation = JSON.parse(userLocation);

    for (const address of addressData || []) {
      const isMatchingLocation =
        parseFloat(address.latitude) === parseFloat(parsedLocation.lat) &&
        parseFloat(address.longitude) === parseFloat(parsedLocation.lng);

      if (isMatchingLocation) {
        setMatchingAddressDetails(address);
        break; // Exit loop after finding a match
      }
    }
  }, [userLocation, userSelected, addressData]);

  useEffect(() => {
    localStorage.removeItem("restaurantIdForRedirect");
    localStorage.removeItem("preserevedBranchId");
    localStorage.removeItem("preserevedRestaurantID");

    dispatch(removeResturantsDetails());
    dispatch(removeCategoryItemList());
    dispatch(removeCategoryList());

    localStorage.removeItem("restaurantID");
    // localStorage.removeItem("branchId");
    localStorage.removeItem("restaurantName");
    localStorage.removeItem("menuDetailId");
  }, [cart, resturantsData]);

  useEffect(() => {
    if (selectedAddress !== null) {
      const addToStore = {
        lat: selectedAddress.lat,
        lng: selectedAddress.lng,
        address: selectedAddress.address,
        addressId,
        delivery_charges,
        delivery_time,
        name_en,
        restaurantName: restName,
      };

      localStorage?.setItem("selected", JSON.stringify(addToStore));
      dispatch(addSelectedAddress(addToStore));
    }
  }, [selectedAddress]);

  useEffect(() => {
    if (!isCountry) {
      fetchAllCountries();
    }
    if (token && !addressData.length) {
      fetchFunctionAddresses();
    }
    if (!ranOnceRef.current) {
      fetchAllBranches();
      ranOnceRef.current = true;
    }
  }, []);

  useEffect(() => {
    if (
      dispatchCount === itemsAfterRemove?.length &&
      changeAddressDetails?.address !== undefined
    ) {
      setRemovingItem(false);

      changeAddressAfterSelect(changeAddressDetails?.address);

      setClearCartModal(false);
    }
  }, [dispatchCount, itemsAfterRemove]);

  useEffect(() => {
    if (!chooseFullAddress) return;

    const filterBranch = branchHours?.filter(
      (rest) => rest.restaurant_id === cart[0]?.restaurant_id
    );

    if (cart?.length === 0) {
      handleEmptyCartAddress();
      return;
    }

    if (branchHours.length && filterBranch?.length) {
      handleDistanceCheck(filterBranch);
    }
  }, [chooseFullAddress, branchHours]);


  useEffect(() => {
    if (!fullAddress) return;

    fetchSupportRestaurantByArea(selectedText);

    if (!filterSelectedNow) {
      handleBranchHoursDistance();
    } else {
      handleAddressSelection();
    }
  }, [fullAddress, branchHours]);

  useEffect(() => {
    if (token) {
      if (anyStateTrue(typesState) && matchingArea) {
        fetchSupportRestaurantByArea(selectedText);
      } else if (!anyStateTrue(typesState)) {
        dispatch(resetSupportResturants());
      }
    }
    setSelectedFilter(extractSelectedFilter());
  }, [typesState, selectedText]);

  useEffect(() => {
    const deliveryText = t("delivery");
    const availableForDelivery = t("availableForDelivery");

    if (selectedText === deliveryText) {
      if (
        filterClassNear &&
        filterSelectedNow !== availableForDelivery &&
        supportRestaurant?.length
      ) {
        removeBrownClasses(divs, parentIcon);
        if (matchingArea) {
          addClasses(divs, parentIcon, supportRestaurant);
        }
      } else if (filterClassDelivery && !filterClassNear) {
        if (matchingArea) {
          addBrownClasses(divs, parentIcon, supportRestaurant);
        }
      } else if (!filterClassDelivery && !filterClassNear) {
        removeClasses(divs, parentIcon);
        removeBrownClasses(divs, parentIcon);
      }
    }
  }, [
    filterClassNear,
    filterClassDelivery,
    selectedText,
    filterSelectedNow,
    supportRestaurant,
    fullAddress,
  ]);

  // const pickTime = () => {
  //   const pickupTime =
  //     pickupBranchId !== null &&
  //     allAreas
  //       .find(
  //         (area) =>
  //           area?.branch_id === pickupBranchId &&
  //           area.restaurant_id === cart[0]?.restaurant_id
  //       )
  //       ?.pickup_time?.trim()
  //       ?.split(" ")[0];

  //   return pickupTime ? +pickupTime : 30;
  // };

  return (
    <div className="home-styling container-fluid">
      <div style={{ padding: "8px" }}>
        <Navbar
          profile
          setOpenAddressPopup={setOpenAddressToChoose}
          logo
          cartIcon={CartIcon}
          profileIconUrl={token ? paths.profile : paths.guest_profile}
          fromHome={true}
          setPickupFromDialog={setPickupFromDialog}
        />
      </div>

      <div className="card-spacing">
        <Slider />
      </div>

      <RestaurantsOrderTypeButton
        setOpenAddressPopup={
          userSelected === null && token !== null ? setOpenAddressPopup : null
        }
        setToggleFilterClassNear={setToggleFilterClassNear}
        openSelectAddress={setOpenAddressPopup}
        filterClassNear={filterClassNear}
        filterSelectedNow={filterSelectedNow}
        setFilterSelectedNow={setFilterSelectedNow}
        goToRestaurantMenuPage={goToRestaurantMenuPage}
        setToggleFilterClassDelivery={setToggleFilterClassDelivery}
        filterClassDelivery={filterClassDelivery}
        selectedText={selectedText}
        setSelectedText={setSelectedText}
        typesState={typesState}
        setTypesState={setTypesState}
        selectedFilter={selectedFilter}
      />

      {/* Lazy loaded sliders */}
      <Suspense fallback={<div>Loading...</div>}>
        <LazyLoadSlider
          {...sliderProps}
          apiCall={getAllPopularItems}
          apiQuery={GET_ALL_POPULAR_ITEMS_QUERY_KEY}
          title={t("popularDishes")}
        />
        <LazyLoadSlider
          {...sliderProps}
          apiCall={getIftarItems}
          apiQuery={GET_IFTAR_ITEMS_QUERY_KEY}
          title={t("gatherSelections")}
        />
        <LazyLoadSlider
          {...sliderProps}
          apiCall={getGatheringItems}
          apiQuery={GET_GATHERING_ITEMS_QUERY_KEY}
          title={t("cakeSelections")}
          onLoad={() => setIsLastSliderLoaded(true)}
        />
      </Suspense>

      {isLastSliderLoaded && (
        <Box sx={{ marginTop: "60px" }}>
          <LazyLoadFooter />
        </Box>
      )}

      {/* Suspense components */}
      <Suspense fallback={<div>Loading...</div>}>
        {openAddressPopup && (
          <AddressDialog
            openAddressPopup={openAddressPopup}
            handleClose={handleClose}
            addressData={addressData}
            chooseAddress={chooseAddress}
            navigateUser={navigateUser}
          />
        )}
        {openAddressToChoose && (
          <SelectLocation
            openAddressPopup={openAddressToChoose}
            handleClose={() => setOpenAddressToChoose(false)}
            chooseAddress={onChooseFullAddress}
            navigateUser={onNavigateUser}
          />
        )}
        {distanceIsFar && (
          <DialogComponent
            notDeliver={distanceIsFar}
            setNotDeliver={setDistanceIsFar}
            restaurantName={restaurantName}
            fromDetail={true}
            resturantId={restaurantId}
            location={location}
            newText={newText}
            home={true}
          />
        )}
        {openSchedulePopup && (
          <SchedulePicker
            openDatePicker={openSchedulePopup}
            setOpenDatePicker={setOpenSchedulePopup}
            showTimePicker={showTimePicker}
            setShowTimePicker={setShowTimePicker}
          />
        )}
        {restaurantOpen && (
          <ScheduleDialog
            restaurantOpen={restaurantOpen}
            setRestaurantOpen={setRestaurantOpen}
            setOpenSchedulePopup={setOpenSchedulePopup}
            home={true}
          />
        )}
        {clearCartModal && (
          <RemoveFromCart
            clearCartModal={clearCartModal}
            setClearCartModal={setClearCartModal}
            textToShow={t("branchChangeWraning", { ns: "dialog" })}
            clearFunction={clearFunction}
            itemsAfterRemove={itemsAfterRemove}
            removingItem={removingItem}
          />
        )}
        {pickupFromDialog && (
          <PickupFrom
            pickupFromDialog={pickupFromDialog}
            handleClose={() => setPickupFromDialog(false)}
            branchHours={filterPickupRestaurants(
              allBranches?.filter(
                (branch) => branch?.restaurant_id === cart[0]?.restaurant_id
              ),
              resturantsData
            )}
            restaurant_logo={
              allBranches?.find(
                (branch) => branch?.restaurant_id === cart[0]?.restaurant_id
              )?.restaurant_logo
            }
            setOpenDatePicker={setOpenSchedulePopup}
            navigateTo="/cart"
          />
        )}
      </Suspense>
    </div>
  );
};

export default Home;
